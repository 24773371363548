import Vue from "vue/dist/vue.esm.js";
import axios from "axios";
var moment = require("moment");

if ($("#app").length) {
  var app = new Vue({
    el: "#app",
    filters: {
      moment_date: function (date) {
        return moment(date).format("YYYY.MM.DD");
      },
      moment_eventDate: function (date) {
        return moment(date).format("YYYYMMDD");
      },
      textLowerCase: function (obj) {
        return obj.toLowerCase();
      },
      contentsRender: function (contents) {
        const paragraph = contents;
        const regex = /<img.*?src\s*=\s*[\"|\'](.*?)[\"|\'].*?>/i;
        const found = paragraph.match(regex);
        console.log(found[1]);
        return found[1];
      },
    },
    data: {
      localStorages_list: [],
      page: "",
      // count: 6
    },
    computed: {
      localStorageItems() {
        let list = this.localStorages_list;
        return list;
      },
      setToday: function () {
        var d = moment();
        var d = d.format("YYYY.MM.DD");
        return d;
      },
      limitCount() {
        let arg = new Object();
        let pair = location.search.substring(1).split("&");
        for (let i = 0; pair[i]; i++) {
          let kv = pair[i].split("=");
          arg[kv[0]] = kv[1];
        }
        let start = arg.page > 1 ? (arg.page - 1) * 30 : 0;
        let end = start + 30;
        this.page = arg.page;
        // console.log(this.page);
        return this.localStorages_list.slice(start, end);
      },
    },
    methods: {
      perPage() {
        let remainder = this.localStorages_list.length % 30 ? 1 : 0;
        return Math.floor(this.localStorages_list.length / 30) + remainder;
      },
      // isMore() {
      //   this.count += 6
      // }
    },
    async mounted() {
      try {
        // var a = [];
        var localStorages = localStorage.getItem("c-favorite_article");
        let res = await axios.get(
          "https://koberu.com/wp-json/custom/v1/items/" + localStorages
        );
        this.localStorages_list = res.data;
      } catch (e) {
        console.log("投稿がありません");
        $(".page-favorite-loading").text(
          "お気に入り登録された記事がありません。"
        );
      }
    },
  });
}
